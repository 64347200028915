import React, { useState, useEffect, useContext } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import ModalBasic from '../components/ModalBasic';
import ModalBlank from '../components/ModalBlank';
import toast, { Toaster } from 'react-hot-toast';
import AppContext from '../utils/AppContext';

import DataTable from 'react-data-table-component';
import Tooltip from '../components/Tooltip';

function EndpDevice() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputModel, setInputModel] = useState('');
  const [inputDeviceId, setInputDeviceId] = useState('');
  const [inputLocation, setInputLocation] = useState('');
  const [inputMemo, setInputMemo] = useState('');
  const [isNewDevice, setIsNewDevice] = useState();

  const { userInfo, setUserInfo } = useContext(AppContext);
  if (!userInfo || !userInfo.email) {
    window.location.href = '/signin';
  }

  // const num2dot = (num) => {
  //   var d = num % 256;
  //   for (var i = 3; i > 0; i--) {
  //     num = Math.floor(num / 256);
  //     d = (num % 256) + '.' + d;
  //   }
  //   return d;
  // };

  const fetchDevicePage = (page) => {
    if (!userInfo?.teamIdx) return;

    setLoading(true);

    fetch(`https://api.endp.info/api/v1/endp/device?teamIdx=${userInfo.teamIdx}&pageCount=${perPage}&pageIndex=${page}`)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setTotalRows(json.totalCount);
          setData(json.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => setLoading(false));
  };

  const fetchDeviceNew = () => {
    setLoading(true);

    const postData = {
      teamIdx: userInfo.teamIdx,
      model: inputModel,
      deviceId: inputDeviceId,
      name: inputName,
      location: inputLocation,
      memo: inputMemo,
    };

    fetch(`https://api.endp.info/api/v1/endp/device`, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          // setToastMsg('Saved new device!');
          // setToastSuccessOpen(true);

          toast.success('Successfully save new device!');
          setEditModalOpen(false);

          fetchDevicePage(1);
        } else {
          toast.error('Failed to save. Check the fields!');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setToastMsg('Failed to saved new device...');
        // setToastWarningOpen(true);
        toast.error('Failed to saved new device...');
      });
  };

  const fetchDeviceEdit = () => {
    setLoading(true);

    const row = selectedRows[0];

    const postData = {
      model: inputModel,
      deviceId: inputDeviceId,
      name: inputName,
      location: inputLocation,
      memo: inputMemo,
    };

    fetch(`https://api.endp.info/api/v1/endp/device/${row.didx}`, {
      method: 'PUT',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          // setToastMsg('Saved new device!');
          // setToastSuccessOpen(true);

          setEditModalOpen(false);
          toast.success('Successfully save device!');

          fetchDevicePage(1);
        } else {
          toast.error('Failed modify to device information. alread exiest device.');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setToastMsg('Failed to saved new device...');
        // setToastWarningOpen(true);
        toast.error('Failed modify to device information');
      });
  };

  const fetchDeviceDelete = () => {
    setLoading(true);

    const row = selectedRows[0];

    fetch(`https://api.endp.info/api/v1/endp/device/${row.didx}`, {
      method: 'DELETE',
      cache: 'no-cache',
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === 'OK') {
          // setToastMsg('Saved new device!');
          // setToastSuccessOpen(true);

          setDeleteModalOpen(false);
          toast.success('Successfully save device!');

          fetchDevicePage(1);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // setToastMsg('Failed to saved new device...');
        // setToastWarningOpen(true);
        toast.error('Failed to saved new device...');
      });
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setLoading(true);

    setPerPage(newPerPage);
    fetchDevicePage(page);

    setLoading(false);
  };

  const onSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const onClickRemote = (remoteProtocol, ip, remotePort) => {
    const url = `${remoteProtocol}://${ip}:${remotePort}`;

    window.open(url, '_blank', 'noopener');
  };

  useEffect(() => {
    fetchDevicePage(1);
  }, []);

  const columns = [
    {
      name: 'No',
      selector: (row) => row.didx,
      sortable: true,
      compact: true,
      center: true,
      width: '60px',
    },
    {
      name: 'IP',
      // selector: (row) => row.remoteIp,
      cell: (row) => (
        <div>
          {row.ddidx ? (
            <>
              <div className="cursor-pointer" onClick={() => window.open('http://' + row.remoteIp, '_blank', 'noopener')}>
                {row.ip || row.remoteIp}
              </div>
              <div>
                <button
                  className="px-2 border-blue-400 border bg-blue-200 rounded-full"
                  onClick={() => window.open('http://' + (row.ip || row.remoteIp), '_blank', 'noopener')}>
                  Remote Web
                </button>
              </div>
            </>
          ) : (
            <div>Disconnect</div>
          )}
        </div>
      ),
      sortable: true,
      compact: true,
      maxWidth: '100px',
    },
    {
      name: 'Name / Model',
      // selector: (row) => `${row.name}`,
      cell: (row) => (
        <div>
          <div>{row.name}</div>
          <div>{`(${row.model})`}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
      compact: true,
      minWidth: '80px',
    },
    {
      name: 'SN / IMEI',
      // selector: (row) => `${row.deviceId}\n\n(${row.imei ? row.imei : ''})`,
      cell: (row) => (
        <div>
          <div>{row.deviceId}</div>
          <div>{row.imei}</div>
        </div>
      ),
      sortable: true,
      wrap: true,
      compact: true,
    },
    {
      name: 'GPS',
      // selector: (row) => `${row.lat}E, ${row.lng}N`,
      cell: (row) => (
        <div>
          {row.lat === 0 && row.lng === 0 ? null : row.lat && row.lng ? (
            <div className="flex flex-row align-middle">
              <div className="w-{116}">
                <div>{row.lat + ','}</div>
                <div>{row.lng}</div>
              </div>
              <a href={`/endp/map/${row.lat}/${row.lng}/${row.name || 'NONAME'}`} target="_blank" rel="noreferrer">
                <svg x="0px" y="0px" viewBox="0 0 36 36" width="24" height="32" align="right">
                  <path
                    fill="#1f8aff"
                    d="M18,6.7c-3.2,0-5.7,2.6-5.7,5.7s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7l0,0C23.7,9.3,21.2,6.7,18,6.7z M18,16.2
                    c-2.1,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7c2.1,0,3.7,1.7,3.7,3.7v0C21.7,14.5,20.1,16.2,18,16.2L18,16.2z"
                  />
                  <path
                    fill="#1f8aff"
                    d="M18,2C11.5,2,6.3,7.2,6.2,13.7c0,4.7,2.6,8.6,4.5,11.4l0.4,0.5c1.9,2.8,4,5.4,6.1,8l0.8,0.9l0.8-0.9c2.2-2.6,4.2-5.2,6.1-8
                    l0.4-0.5c1.9-2.9,4.5-6.8,4.5-11.4C29.8,7.2,24.5,2,18,2z M23.6,24l-0.4,0.5c-1.7,2.6-4,5.5-5.2,6.9c-1.2-1.4-3.5-4.3-5.2-6.9
                    L12.4,24c-1.8-2.6-4.2-6.2-4.2-10.3c0-5.4,4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8c0,0,0,0,0,0C27.8,17.8,25.4,21.4,23.6,24z"
                  />
                </svg>
              </a>
            </div>
          ) : null}
        </div>
      ),
      sortable: true,
      compact: true,
      width: '120px',
    },
    {
      name: 'Location',
      selector: (row) => row.location,
      sortable: true,
      compact: true,
    },
    {
      name: 'Signal',
      cell: (row) => {
        // if(!row.rsrp) return null;
        const s = row.rsrp || -112;
        return (
          <Tooltip message={s + ' dBm'}>
            <svg
              className="tooltip"
              width="18"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 36 36"
              alt={row.rsrp}>
              {s >= -83 ? (
                <>
                  <path fill="#000000" d="M30.3,0H36v36h-5.7V0z" />
                  <path fill="#000000" d="M20,9h5.5v27H20V9z" />
                  <path fill="#000000" d="M9.7,18h5.5v18H9.7V18z" />
                  <path fill="#000000" d="M0,27h5.5v9H0V27z" />
                </>
              ) : s >= -93 ? (
                <>
                  <path fill="#000000" d="M20,9h5.5v27H20V9z" />
                  <path fill="#000000" d="M9.7,18h5.5v18H9.7V18z" />
                  <path fill="#000000" d="M0,27h5.5v9H0V27z" />
                </>
              ) : s >= -102 ? (
                <>
                  <path fill="#000000" d="M9.7,18h5.5v18H9.7V18z" />
                  <path fill="#000000" d="M0,27h5.5v9H0V27z" />
                </>
              ) : s >= -111 ? (
                <>
                  <path fill="#000000" d="M0,27h5.5v9H0V27z" />
                </>
              ) : (
                <>
                  <path fill="#000000" d="M6,30h22v6h-22V30z" />
                </>
              )}
            </svg>
          </Tooltip>
        );
      },
      sortable: true,
      compact: true,
      center: true,
      width: '50px',
    },
    {
      name: 'Serial/Power',
      // selector: (row) => 'N/A',
      cell: (row) => (
        <div>
          <div className="mb-1 px-2 text-center ">{row.serial ? row.serial : 'OFF'}</div>
          <div className="px-2 text-center bg-blue-300 rounded-full cursor-pointer">{row.pwctrl ? row.pwctrl : 'N/A'}</div>
        </div>
      ),
      sortable: true,
      compact: true,
      center: true,
      width: '92px',
    },
    {
      name: 'Telnet',
      // selector: (row) => 'N/A',
      cell: (row) => (
        <div>
          {row.remoteProtocol === 'telnet' && row.remotePort && <div className="mb-1 px-2 text-center ">{row.remotePort}</div>}
          {(row.ddidx && row.remoteProtocol === 'telnet') & row.remotePort ? (
            <div
              className="px-2 text-center border-green-500 border bg-green-200 rounded-full cursor-pointer"
              onClick={() => onClickRemote(row.remoteProtocol, row.ip || row.remoteIp, row.remotePort)}>
              Connect
            </div>
          ) : (
            <div className="px-2 text-center"> {''} </div>
          )}
        </div>
      ),
      sortable: true,
      compact: true,
      center: true,
      width: '92px',
    },
    {
      name: 'Modified',
      selector: (row) => row.modified,
      sortable: true,
      compact: true,
      hide: 'lg',
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => true, //row.mdn && row.mdn !== '',
      style: (row) => ({
        backgroundColor: !row.ddidx
          ? '#ffddde60'
          : row.connectType === 'MOBILE' || row.connectType === 'lte'
            ? '#5094d360'
            : row.connectType === 'WIFI' || row.connectType === 'wifi'
              ? '#94d35060'
              : row.connectType === 'wired'
                ? '#76FCFF80'
                : '#ffddde60',
      }),
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* breadcrumb */}
            <nav className="flex mb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <a
                      href="#"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                      {'EndPoint Nodes'}
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{'Device List'}</span>
                  </div>
                </li>
              </ol>
            </nav>

            {/* button */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 mr-4">
              {/* <div className="flex flex-wrap items-right -m-1.5"> */}
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                aria-controls="edit-modal"
                onClick={(e) => {
                  e.stopPropagation();

                  if (!userInfo.teamIdx) {
                    toast.error('Register your team or accept invitations first');
                    return;
                  }

                  //
                  setIsNewDevice(true);

                  setDeviceIndex('');
                  setInputName('');
                  setInputModel('');
                  setInputDeviceId('');
                  setInputLocation('');
                  setInputMemo('');

                  setEditModalOpen(true);
                }}>
                <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="ml-2">Add</span>
              </button>

              <button
                className="btn border-slate-200 hover:border-slate-300 text-slate-600"
                aria-controls="edit-modal"
                onClick={(e) => {
                  e.stopPropagation();

                  if (!userInfo.teamIdx) {
                    toast.error('Register your team or accept invitations first');
                    return;
                  }

                  if (selectedRows.length !== 1) {
                    toast.error('Need select only 1 row');
                    return;
                  }

                  setIsNewDevice(false);

                  const row = selectedRows[0];
                  setDeviceIndex(row.didx || '');
                  setInputName(row.name || '');
                  setInputModel(row.model || '');
                  setInputDeviceId(row.deviceId || '');
                  setInputLocation(row.location || '');
                  setInputMemo(row.memo || '');

                  setEditModalOpen(true);
                }}>
                <svg className="w-4 h-4 fill-current text-slate-500 shrink-0" viewBox="0 0 16 16">
                  <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                </svg>
                <span className="ml-2">Edit</span>
              </button>

              <ModalBasic
                id="edit-modal"
                modalOpen={editModalOpen}
                setModalOpen={setEditModalOpen}
                title={isNewDevice ? '✨ New Device' : '✏️ Edit Device'}>
                {/* Modal content */}
                <div className="px-5 py-4">
                  <div className="text-sm">
                    {!isNewDevice && (
                      <div className="font-medium text-slate-400 mb-2">
                        {/* <label className="block text-sm font-medium mb-1" htmlFor="inputIndex"> */}
                        Device index
                        {/* </label> */}
                        <input
                          id="inputIndex"
                          className="form-input w-full disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                          type="text"
                          value={deviceIndex}
                          disabled
                        />
                      </div>
                    )}

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Name
                      <input
                        id="inputName"
                        className="form-input w-full"
                        type="text"
                        value={inputName}
                        onChange={(e) => setInputName(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Model
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={inputModel}
                        onChange={(e) => setInputModel(e.target.value)}
                      />
                      <div className="text-xs mt-1 text-rose-500">{!inputModel ? 'This field is required!' : ' '}</div>
                    </div>

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Device ID or Serial Number:
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={inputDeviceId}
                        onChange={(e) => setInputDeviceId(e.target.value)}
                      />
                      <div className="text-xs mt-1 text-rose-500">{!inputDeviceId ? 'This field is required!' : ' '}</div>
                    </div>

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Location:
                      <input
                        id="default"
                        className="form-input w-full"
                        type="text"
                        value={inputLocation}
                        onChange={(e) => setInputLocation(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Memo:
                      <textarea
                        id="default"
                        className="form-input w-full"
                        type="textarea"
                        value={inputMemo}
                        onChange={(e) => setInputMemo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-slate-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                      onClick={(e) => {
                        e.stopPropagation();

                        setEditModalOpen(false);
                      }}>
                      Close
                    </button>
                    <button
                      className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!inputModel) {
                          toast.error('input need model value');
                          return;
                        }
                        if (!inputDeviceId) {
                          toast.error('input need Device ID or Serial no. value');
                          return;
                        }

                        if (isNewDevice) fetchDeviceNew();
                        else fetchDeviceEdit();
                      }}>
                      {'Save'}
                    </button>
                  </div>
                </div>
              </ModalBasic>

              <button
                className="btn border-slate-200 hover:border-slate-300 text-rose-500"
                aria-controls="danger-modal"
                onClick={(e) => {
                  e.stopPropagation();

                  if (!userInfo.teamIdx) {
                    toast.error('Register your team or accept invitations first');
                    return;
                  }

                  if (selectedRows.length !== 1) {
                    toast.error('Need select only 1 row');
                    return;
                  }

                  setDeleteModalOpen(true);
                }}>
                <svg className="w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                  <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                </svg>
                <span className="ml-2">Delete</span>
              </button>

              <ModalBlank id="delete-modal" modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen}>
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
                    <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-slate-800">Delete Selected Device?</div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        {/* <p>Semper eget duis at tellus at urna condimentum mattis pellentesque lacus suspendisse faucibus interdum.</p> */}
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalOpen(false);
                        }}>
                        {'Cancel'}
                      </button>
                      <button
                        className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                        onClick={(e) => {
                          e.stopPropagation();

                          if (selectedRows.length !== 1) {
                            alert('Need select only 1 row');
                            return;
                          }

                          fetchDeviceDelete();
                        }}>
                        {'Yes'}
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
            </div>

            {/* Device List */}
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              // defaultSortFieldId={1}
              // defaultSortAsc={false}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={fetchDevicePage}
              selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
              selectableRowsVisibleOnly={true}
              selectableRowsSingle={true}
            />
          </div>
        </main>

        <Toaster />
      </div>
    </div>
  );
}

export default EndpDevice;
